import { UserType } from '@hxp/graphql';

export const defaultUserInformation = {
  id: '',
  name: { givenName: '', familyName: '' },
  userName: '',
  email: { value: '' },
  displayName: '',
  archived: false,
  deactivated: false,
  locked: false,
  emailVerified: false,
  mfaEnabled: false,
  groups: [],
  type: UserType.Interactive,
  account: { id: '', mfaSettings: { mfaEnabled: false } },
};

export const defaultServiceUserInformation = {
  id: '',
  name: { givenName: '', familyName: '' },
  userName: '',
  email: { value: '' },
  displayName: '',
  archived: false,
  deactivated: false,
  locked: false,
  emailVerified: false,
  mfaEnabled: false,
  groups: [],
  type: UserType.Service,
  account: { id: '', mfaSettings: { mfaEnabled: false } },
};
