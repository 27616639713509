import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';

interface GraphQLError {
  readonly message: string;
  readonly path: readonly string[];
  readonly extensions: Extension;
}

export interface GraphQLErrorResponse {
  readonly errors: readonly GraphQLError[];
}

interface Extension {
  readonly code: string;
  readonly serviceName: string;
}

export const AUTH_NOT_AUTHENTICATED_CODE = 'AUTH_NOT_AUTHENTICATED';

const isHttpResponse = <T>(val: HttpEvent<T>): val is HttpResponse<T> => val.type === HttpEventType.Response;

const isGraphqlErrorResponse = (res: HttpResponse<unknown>): res is HttpResponse<GraphQLErrorResponse> & { body: GraphQLErrorResponse } => {
  const errors = (res.body as GraphQLErrorResponse | null)?.errors;
  return !!errors && errors.length > 0;
};

const hasGraphqlAuthError = (errors: readonly GraphQLError[]): boolean => {
  return errors.some((error) => error.extensions.code === AUTH_NOT_AUTHENTICATED_CODE);
};

export const hasAuthErrorInGraphqlResponse = <T>(val: HttpEvent<T>): boolean => {
  if (isHttpResponse(val) && isGraphqlErrorResponse(val)) {
    return hasGraphqlAuthError(val.body.errors);
  }

  return false;
};
