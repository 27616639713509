import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FeatureFlagContainer, FeatureFlagResponse } from '../../models/feature-flag';
import { FeatureFlagStrategy } from '../feature-flag.strategy';

export const ADDITIONAL_FEATURE_FLAGS = new InjectionToken<FeatureFlagContainer>('Additional feature flags to use for local testing');

@Injectable()
export class FeatureFlagStrategyLocal extends FeatureFlagStrategy {
  private readonly _localFlags: FeatureFlagContainer = {
    'nucleus-ce-non-provisioned-apps': true,
    'nucleus-ce-show-override-flag-options': true,
    'ce-backlog': true,
    'archive-user': true,
  };

  constructor(@Optional() @Inject(ADDITIONAL_FEATURE_FLAGS) additionalFeatureFlags?: FeatureFlagContainer) {
    super();

    if (additionalFeatureFlags) {
      this._localFlags = { ...this._localFlags, ...additionalFeatureFlags };
    }
  }

  getFlag(flagKey: string): Observable<FeatureFlagResponse> {
    const value = this._localFlags[flagKey] ?? false;
    return of({ value });
  }

  getAllFlags(flags: string[]): Observable<FeatureFlagContainer> {
    return of(this._filterFlags(flags));
  }

  private _filterFlags(flags: string[]): FeatureFlagContainer {
    return Object.fromEntries(
      Object.entries(this._localFlags).filter(([key]) => {
        return flags.includes(key);
      }),
    );
  }
}
