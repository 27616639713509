<div *transloco="let t; read: 'users.users-components.add-user-to-groups'">
  <div class="dual-list-wrapper" *ngIf="unassignedGroups || isLoading">
    <ng-container *ngIf="!isLoading; else ghost">
      <hy-dual-list
        *ngIf="unassignedGroups"
        class="dual-list-component dual-list-component--with-bar"
        availableListLabel="{{ t('available-users-list-label') }}"
        selectedListLabel="{{ t('selected-users-list-label') }}"
        [compact]="false"
        [selectableItems]="unassignedGroups"
        [value]="preselectedUserGroups"
        (valueChange)="onSelectedItemsChanged($event)"
        [trackBy]="trackById"
      >
        <ng-container *hyDualListItem="let dataItem">
          <div
            class="dual-list-truncate block max-w-full whitespace-nowrap text-ellipsis overflow-hidden"
            [attr.title]="dataItem.displayName"
          >
            {{ dataItem.displayName }}
          </div>
        </ng-container>
      </hy-dual-list>
    </ng-container>

    <ng-template #ghost>
      <div class="dual-list-component grid grid-cols-2 gap-6">
        <hy-ghost-block class="ghost-block-container h-full"></hy-ghost-block>
        <hy-ghost-block class="ghost-block-container h-full"></hy-ghost-block>
      </div>
    </ng-template>
  </div>
</div>
