import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable, isDevMode } from '@angular/core';
import { HY_AUTH_BEFORE_LOGOUT, HyAuthLoginCallbackParams, HyAuthLoginParam, HyAuthService, HyAuthState } from '@hyland/ui';
import * as R from 'ramda';
import { BehaviorSubject, EMPTY, lastValueFrom, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { BffConfigService } from './utils/bff-config.service';

const ANONYMOUS: Session = null;
const CACHE_SIZE = 1;

// cannot use the standard interceptor because we need to avoid the hy-interceptors due to circular references
const userApiCallHeaders = new HttpHeaders().set('X-CSRF', '1');
const noop = () => Promise.resolve();

export interface Claim {
  readonly type: string;
  readonly value: string;
}

export type Session = Claim[] | null;

interface SessionControl {
  readonly clear: boolean;
}

const userIsAuthenticated = (s: Session): s is Claim[] => s !== null;

@Injectable({ providedIn: 'root' })
export class BFFAuthService extends HyAuthService {
  private readonly _bffConfig = inject(BffConfigService);
  private readonly _httpBackend = inject(HttpBackend);
  private readonly _beforeLogoutFn = inject(HY_AUTH_BEFORE_LOGOUT, { optional: true }) || noop;
  private readonly _authState$ = new BehaviorSubject(false);
  private readonly _sessionControlSub$ = new BehaviorSubject<SessionControl>({ clear: false });

  // local properties
  private logoutUrl: string | undefined = undefined;
  private httpClient = new HttpClient(this._httpBackend);

  readonly session$ = this._sessionControlSub$.asObservable().pipe(
    switchMap((sessionControl) => {
      if (sessionControl.clear) {
        return of(ANONYMOUS);
      }

      return this._getSession();
    }),
    shareReplay(CACHE_SIZE),
  );

  private readonly _userProfile$ = this.session$.pipe(
    take(1),
    filter(userIsAuthenticated),
    map((session) => R.fromPairs(session.map(({ type, value }) => [type, value]))),
  );

  readonly authenticated$ = this.session$.pipe(
    map(userIsAuthenticated),
    tap((isAuthenticated) => {
      console.log(`Setting auth state to ${isAuthenticated}`);
      this._authState$.next(isAuthenticated);
    }),
    distinctUntilChanged(),
    shareReplay(CACHE_SIZE),
  );

  // should always be an empty observable.
  readonly idpUnreachable$ = EMPTY;

  get authenticated(): boolean {
    console.log(`Returning ${this._authState$.value} to the auth project check used only by the guard statement.`);
    return this._authState$.value;
  }

  private _getSession() {
    const backendUserUrl = this._bffConfig.getBackendUrl('/bff/user');
    return this.httpClient.get<Session>(backendUserUrl, { headers: userApiCallHeaders, withCredentials: true }).pipe(
      catchError((err) => {
        console.log('setting session to ANONYMOUS');
        console.log(err);
        console.log('The above is the error from calling the BFF User endpoint.');
        return of(ANONYMOUS);
      }),
      tap(this._setLogoutUrl.bind(this)),
    );
  }

  clearSession(): void {
    this._sessionControlSub$.next({ clear: true });
  }

  private _setLogoutUrl(session: Session) {
    if (!userIsAuthenticated(session) || this.logoutUrl) {
      return;
    }

    this.logoutUrl = session.find(({ type }) => type === 'bff:logout_url')?.value;
    console.log(`Setting Logout URL to ${this.logoutUrl}`);
  }

  // In BFF, the tokens never touch the browser.
  override getIdToken() {
    return undefined;
  }
  override getAccessToken() {
    return undefined;
  }

  // Standard BFF user profile API call?
  override getUserProfile<T = Record<string, string>>(): Promise<T> {
    return lastValueFrom(this._userProfile$ as Observable<T>);
  }

  override login(params?: HyAuthLoginParam | undefined): void {
    const loginBffUrl = this._bffConfig.getBackendUrl('/bff/login');
    const returnUrl = isDevMode() ? `${window.location.origin}${params?.state?.route}` : params?.state?.route;
    const redirectUrl = returnUrl !== undefined ? `${loginBffUrl}?returnUrl=${returnUrl}` : loginBffUrl;

    const url = new URL(redirectUrl, window.origin);
    window.location.href = url.toString();
  }

  // this isn't really needed in the BFF model, but this overcomes the way the Hy-UI is expecting the
  // auth to work and forces the session to populate before the auth guard can trigger on the homepage.
  loginCallback(_params?: HyAuthLoginCallbackParams | undefined): Promise<HyAuthState<Record<string, unknown>> | undefined> {
    console.log('Calling loginCallback function');
    return Promise.resolve({ route: '/' });
    // since this is an auth login callback, bust the cache.
    // return firstValueFrom(this.getSession(true).pipe(
    //   map(session => {
    //     console.log('return hy state with empty route');
    //     return { route: '/' };
    //   })
    // ));
  }

  override async logout() {
    await this._beforeLogoutFn();
    if (this.logoutUrl) {
      window.location.href = this._bffConfig.getBackendUrl(this.logoutUrl);
    }
  }
}
