import { inject, Injectable, Injector } from '@angular/core';
import { FakeAuthService } from '@hxp/shared/auth';
import { HyAuthService } from '@hyland/ui';
import { BFFAuthService } from './auth.bff.service';

@Injectable({ providedIn: 'root' })
export class AuthServiceLocator {
  private readonly _injector = inject(Injector);

  getAuthService(useFake: boolean): HyAuthService {
    return useFake ? this._injector.get(FakeAuthService) : this._injector.get(BFFAuthService);
  }
}
