import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { HyErrorLayoutModule, HyTranslateModule } from '@hyland/ui';
import { HyShellCoreComponentsModule } from '@hyland/ui-shell';

import { HyAuthService } from '@hyland/ui/auth';

@Component({
  templateUrl: './unauthenticated.view.html',
  styleUrls: ['./unauthenticated.view.scss'],
  standalone: true,
  imports: [CommonModule, HyTranslateModule, MatButtonModule, HyShellCoreComponentsModule, RouterModule, HyErrorLayoutModule],
})
export class UnauthenticatedView implements OnInit {
  private readonly _authService = inject(HyAuthService);
  private readonly _dialog = inject(MatDialog);
  private readonly _document = inject(DOCUMENT);

  ngOnInit(): void {
    this._dialog.closeAll();
  }

  login() {
    void this._authService.login({ state: { route: this._document.location.pathname } });
  }
}
