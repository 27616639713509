import { AnalyticsConfig, defaultAnalyticsConfig } from '@hxp/shared/analytics';
import { autoImplement } from './utils/auto-implement';

export interface ConfigurationOptions {
  useFakeAuth: boolean;
  production: boolean;
  backendHost: string;
  graphqlApiUrl: string;
  auditExportApiUrl: string;
  termsConditionsUrl: string;
  privacyPolicyUrl: string;
  usagePolicyUrl: string;
  analytics: AnalyticsConfig;
  helpUrl?: string;
}

export class Configuration extends autoImplement<ConfigurationOptions>() {
  constructor(config?: Partial<ConfigurationOptions>) {
    super();

    this.useFakeAuth = config?.useFakeAuth || false;
    this.production = config?.production || true;
    this.backendHost = config?.backendHost || '';
    this.graphqlApiUrl = config?.graphqlApiUrl || '';
    this.auditExportApiUrl = config?.auditExportApiUrl || '';
    this.termsConditionsUrl = config?.termsConditionsUrl || '';
    this.privacyPolicyUrl = config?.privacyPolicyUrl || '';
    this.usagePolicyUrl = config?.usagePolicyUrl || '';
    this.analytics = config?.analytics || defaultAnalyticsConfig;
    this.helpUrl = config?.helpUrl ?? '';
  }
}
