import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { HyAuthModule, HyAuthService } from '@hyland/ui';

import introspectionResult from '@hxp/graphql';

import { ConfigurationService } from '@hxp/shared/configuration';

/* cspell:words inmemory */

export function apolloOptionsFactory(httpLink: HttpLink, config: ConfigurationService) {
  const { graphqlApiUrl } = config.getConfig();

  return {
    cache: new InMemoryCache({
      possibleTypes: introspectionResult.possibleTypes,
    }),
    link: ApolloLink.from([httpLink.create({ uri: graphqlApiUrl, withCredentials: true })]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  };
}

@NgModule({
  imports: [ApolloModule, HyAuthModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloOptionsFactory,
      deps: [HttpLink, ConfigurationService, HyAuthService],
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class GraphQLModule {}
