import { InjectionToken } from '@angular/core';

export interface ActivityMonitorConfig {
  /**
   * This is the duration of inactivity in milliseconds after which the user is marked as inactive.
   */
  readonly inactivityThresholdMs: number;

  /**
   * This interval in milliseconds determines how frequently the system checks for user activity.
   */
  readonly activityCheckIntervalMs: number;
}

export const ACTIVITY_MONITOR_CONFIG = new InjectionToken<ActivityMonitorConfig>('ACTIVITY_MONITOR_CONFIG', {
  providedIn: 'root',
  factory: () => ({
    activityCheckIntervalMs: 120000, // 2 minutes
    inactivityThresholdMs: 900000, // 15 minutes
  }),
});
