<ng-container *transloco="let t; read: 'shared-bff.unauthenticated-view'">
  <hy-error-layout class="h-auto">
    <hy-error-layout-title> {{ t('title') }}</hy-error-layout-title>
    <hy-error-layout-content>
      <p>{{ t('description') }}</p>
      <p>{{ t('recommendation') }}</p>
    </hy-error-layout-content>
    <hy-error-layout-actions>
      <button mat-flat-button color="primary" (click)="login()">{{ t('button-action-label') }}</button>
    </hy-error-layout-actions>
  </hy-error-layout>
</ng-container>
