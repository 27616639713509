import { inject, Injectable } from '@angular/core';
import { ConfigurationService } from '@hxp/shared/configuration';

@Injectable({
  providedIn: 'root',
})
export class BffConfigService {
  private readonly _config = inject(ConfigurationService);

  getBackendUrl(urlPath: string): string {
    if (this._config.getConfig().backendHost) {
      return this._config.getConfig().backendHost + urlPath;
    }

    return urlPath;
  }
}
