import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AccountAppsGQL } from '@hxp/graphql';
import { HyAuthService } from '@hyland/ui';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private readonly _hyAuthService: HyAuthService,
    private readonly _accountAppsGQL: AccountAppsGQL,
    private readonly _router: Router,
    private readonly _location: Location,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const hasAdminAccess$ = this._accountAppsGQL.watch().valueChanges.pipe(
      filter((resp) => !!resp.data),
      map((resp) => {
        return resp.data.currentUser.accountApps.map((app) => app.appKey).includes('admin');
      }),
    );

    return this._hyAuthService.authenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          void this._hyAuthService.login({ state: { route: state.url } });
          return of(false);
        }

        return hasAdminAccess$.pipe(
          map((hasAdminAccess) => {
            if (!hasAdminAccess) {
              this._redirectToUnauthorized(state);
            }

            return hasAdminAccess;
          }),
          catchError(() => {
            this._redirectToUnauthorized(state);
            return of(false);
          }),
        );
      }),
    );
  }

  private _redirectToUnauthorized(state: RouterStateSnapshot) {
    void this._router.navigate(['/unauthorized'], { skipLocationChange: true }).then(() => {
      this._location.replaceState(state.url);
    });
  }
}
