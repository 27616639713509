import { Provider } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { CONFIG_INITIALIZER } from './config-initializer';
import { SHELL_CONFIG_INITIALIZER } from './shell-config-initializer';
import { TOOLBAR_SERVICE_OVERRIDE } from './shell-toolbar-configuration';

export const CONFIG_PROVIDERS: Provider[] = [
  CONFIG_INITIALIZER,
  SHELL_CONFIG_INITIALIZER,
  TOOLBAR_SERVICE_OVERRIDE,
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useValue: {
      showDelay: 300,
      hideDelay: 0,
      touchendHideDelay: 300,
    },
  },
];

export { configInitializer } from './config-initializer';
export { Configuration } from './configuration';
export { ConfigurationService } from './configuration.service';
export { guaranteeConfigurationExists } from './guaranteeConfigurationExists';
