import { Component, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { configureHyDialogOptions, HyAuthService, HyDialogWidth } from '@hyland/ui';
import { filter, switchMap, take } from 'rxjs/operators';

import { UserType } from '@hxp/graphql';
import { AppsSwitcherService, ShellSettingsService } from '@hxp/kernel';
import { AnalyticsFacade } from '@hxp/shared/analytics';
import { ConfigurationService } from '@hxp/shared/configuration';

import { UserSessionManagementService } from '@hxp/shared/bff';
import { NewSubscriptionDialogService } from './components/new-subscription-dialog/new-subscription-dialog.service';
import { NewSubscriptionDialog } from './components/new-subscription-dialog/new-subscription.dialog';
import { AdminUrlEventDataService } from './services/admin-url-event-data/admin-url-event-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly _sessionManager = inject(UserSessionManagementService);

  readonly userType = UserType;
  readonly helpLink = this._configurationService.getConfig().helpUrl;

  readonly accountApps$ = this._switcherApps.accountApps$;

  constructor(
    private readonly _configurationService: ConfigurationService,
    private readonly _dialog: MatDialog,
    newSubscriptionDialogService: NewSubscriptionDialogService,
    readonly authService: HyAuthService,
    private _urlEventDataService: AdminUrlEventDataService,
    private readonly _analytics: AnalyticsFacade,
    private readonly _switcherApps: AppsSwitcherService,
    private readonly _shellSettings: ShellSettingsService,
  ) {
    authService.authenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        switchMap(() => {
          return newSubscriptionDialogService.showNewSubscriptionDialog$;
        }),
      )
      .subscribe((shouldShowDialog) => {
        if (shouldShowDialog) {
          this._dialog.open(
            NewSubscriptionDialog,
            configureHyDialogOptions({
              width: HyDialogWidth.Medium,
            }),
          );
        }
      });
  }

  ngOnInit() {
    this._urlEventDataService.handleSubscriptionActivation();
    this._shellSettings.forceLightTheme();
    this._analytics.initialize();

    this._sessionManager.manageSession$.subscribe();
  }
}
