import { CanActivateFn } from '@angular/router';

import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, inject } from '@angular/core';

import { HyAuthService } from '@hyland/ui';
import { map } from 'rxjs';

export const authenticationGuard: CanActivateFn = () => {
  const authService: HyAuthService = inject(HyAuthService);
  const platformId = inject(PLATFORM_ID);

  console.log('in bff auth guard');
  const isBrowser = isPlatformBrowser(platformId);

  if (!isBrowser) {
    console.log('failed platform test');
    return false;
  }

  return authService.authenticated$.pipe(
    map((isAuth) => {
      console.log(`got authenticated$ observable as ${isAuth}`);
      if (isAuth === false) {
        void authService.login();

        return false;
      }

      return true;
    }),
  );
};
