import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import {
  BehaviorSubject,
  fromEvent,
  interval,
  map,
  merge,
  Observable,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs';
import { ACTIVITY_MONITOR_CONFIG } from './activity-monitor-config';

export enum UserActivityStatus {
  Active = 'active',
  Idle = 'idle',
  Inactive = 'inactive',
}

const EVENTS = ['click', 'mousemove', 'mouseenter', 'keydown', 'scroll', 'touchstart'];
const createFromEvent = (document: Document) => (event: string) => fromEvent(document, event);

@Injectable()
export class ActivityMonitorService {
  private readonly _config = inject(ACTIVITY_MONITOR_CONFIG);
  private readonly _document = inject(DOCUMENT);

  private readonly _stopMonitoringSub$ = new Subject<void>();

  // use broadcast channel to detect when activity was call from other tabs, to avoid making to much calls for already extended session
  readonly broadcastChannel = new BroadcastChannel('activity-monitor');
  readonly broadcastChannelSub$ = new BehaviorSubject<void>(undefined);

  private readonly _activityEvents$ = merge(...EVENTS.map(createFromEvent(this._document)), this.broadcastChannelSub$.asObservable()).pipe(
    startWith(null),
    // Throttle the events to avoid too many events being emitted in a short period of time
    throttleTime(this._config.activityCheckIntervalMs),
    tap(() => this.broadcastChannel.postMessage('activity')),
  );

  readonly activityStatus$: Observable<UserActivityStatus> = this._activityEvents$.pipe(
    takeUntil(this._stopMonitoringSub$),
    switchMap(() => this.trackUserActivity()),
  );

  constructor() {
    this._initializeBroadcastChannel();
  }

  stopMonitoring() {
    this._stopMonitoringSub$.next();
    this._stopMonitoringSub$.complete();
  }

  trackUserActivity() {
    const recentActivityTime = Date.now();
    return interval(this._config.activityCheckIntervalMs).pipe(
      takeUntil(this._stopMonitoringSub$),
      startWith(recentActivityTime),
      map(() => this._determineActivityStatus(recentActivityTime)),
    );
  }

  private _initializeBroadcastChannel() {
    this.broadcastChannel.onmessage = () => this.broadcastChannelSub$.next();
  }

  private _determineActivityStatus(lastActivityTime: number): UserActivityStatus {
    const elapsedTime = Date.now() - lastActivityTime;
    const isInactive = elapsedTime > this._config.inactivityThresholdMs;
    const isIdle = elapsedTime > this._config.activityCheckIntervalMs;

    if (isInactive) {
      return UserActivityStatus.Inactive;
    }

    if (isIdle) {
      return UserActivityStatus.Idle;
    }

    return UserActivityStatus.Active;
  }
}
