import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, exhaustMap, filter, merge, Observable, of, takeUntil } from 'rxjs';
import { ActivityMonitorService, UserActivityStatus } from './activity-monitor/activity-monitor.service';
import { BFFAuthService } from './auth.bff.service';
import { BffConfigService } from './utils/bff-config.service';

@Injectable()
export class UserSessionManagementService {
  private readonly _activityMonitor = inject(ActivityMonitorService);
  private readonly _httpClient = inject(HttpClient);
  private readonly _authService = inject(BFFAuthService);
  private readonly _bffConfig = inject(BffConfigService);

  private readonly _loggedOut$ = this._authService.authenticated$.pipe(filter((logged) => !logged));

  private readonly _extendUserSession$ = this._activityMonitor.activityStatus$.pipe(
    takeUntil(this._loggedOut$),
    filter((status) => {
      // don't extend the session if the tab is not visible,
      // as the active event, can be triggered from other foreground tab via BroadcastChannel
      return status === UserActivityStatus.Active && document.visibilityState === 'visible';
    }),
    exhaustMap(() => this._extendUserSession()),
  );

  private readonly _checkSession$ = this._activityMonitor.activityStatus$.pipe(
    takeUntil(this._loggedOut$),
    filter((status) => status === UserActivityStatus.Inactive),
    exhaustMap(() => this._checkSession()),
  );

  readonly manageSession$ = merge(this._extendUserSession$, this._checkSession$).pipe(takeUntilDestroyed());

  private _extendUserSession(): Observable<unknown> {
    const backendActivityUrl = this._bffConfig.getBackendUrl('/bff/activity');
    return this._httpClient.post<unknown>(backendActivityUrl, {}, { withCredentials: true }).pipe(catchError(() => of()));
  }

  private _checkSession(): Observable<unknown> {
    const backendUserUrl = this._bffConfig.getBackendUrl('/bff/user');
    return this._httpClient.get<unknown>(backendUserUrl, { withCredentials: true, params: { slide: false } }).pipe(catchError(() => of()));
  }
}
