import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ConfigurationService } from '@hxp/shared/configuration';
import { HyAuthService } from '@hyland/ui';
import { ActivityMonitorService } from './activity-monitor/activity-monitor.service';
import { AuthServiceLocator } from './auth-service-locator.service';
import { CsrfHeaderInterceptor } from './auth.bff.csrf-header.interceptor';
import { BFFAuthService } from './auth.bff.service';
import { BffRoutingModule } from './bff-routing.module';
import { BffUnauthenticatedInterceptor } from './bff-unauthenticated-interceptor/bff-unauthenticated.interceptor';
import { UserSessionManagementService } from './user-session-management.service';
import { UnauthenticatedView } from './views/unauthenticated/unauthenticated.view';

export interface BffModuleConfig {
  readonly useFakeAuth: boolean;
}

@NgModule({
  imports: [UnauthenticatedView, BffRoutingModule],
})
export class BffModule {
  static forRoot(bffConfig?: BffModuleConfig): ModuleWithProviders<BffModule> {
    return {
      ngModule: BffModule,
      providers: [
        ...(bffConfig
          ? [
              {
                provide: HyAuthService,
                useFactory: (authLocator: AuthServiceLocator, config: ConfigurationService) => {
                  const useFakeAuth = bffConfig.useFakeAuth || config.getConfig().useFakeAuth;
                  return authLocator.getAuthService(useFakeAuth);
                },
                deps: [AuthServiceLocator, ConfigurationService],
              },
            ]
          : [{ provide: HyAuthService, useClass: BFFAuthService }]),
        { provide: HTTP_INTERCEPTORS, useClass: CsrfHeaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BffUnauthenticatedInterceptor, multi: true },
        ActivityMonitorService,
        UserSessionManagementService,
      ],
    };
  }
}
