import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from '@hxp/shared/configuration';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BFFAuthService } from '../auth.bff.service';
import { hasAuthErrorInGraphqlResponse } from './graphql-response.utils';

@Injectable()
export class BffUnauthenticatedInterceptor implements HttpInterceptor {
  private readonly _auth = inject(BFFAuthService);
  private readonly _router = inject(Router);
  private readonly _config = inject(ConfigurationService);

  private readonly graphqlApiUrl = this._config.getConfig().graphqlApiUrl;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isGraphQlApiRequest = this.graphqlApiUrl === request.url;

    return next.handle(request).pipe(
      map((res) => {
        if (isGraphQlApiRequest && hasAuthErrorInGraphqlResponse(res)) {
          throw new HttpErrorResponse({ status: 401 });
        }

        return res;
      }),

      catchError((err: HttpErrorResponse) => {
        // This authenticated check is necessary to prevent showing
        // the unauthenticated view when users are entering the page for the first time and are not
        // authenticated yet. In such cases, the guard will redirect them to the login page, but the
        // interceptor might be called before the guard, which would flash
        // the unauthenticated view for a second.

        const isUnauthenticated = err.status === 401;
        const isLoggedIn = this._auth.authenticated;

        if (isUnauthenticated && isLoggedIn) {
          this._handleUnauthenticatedError();
        }

        return throwError(() => err);
      }),
    );
  }

  private _handleUnauthenticatedError(): void {
    this._auth.clearSession();
    this._displayUnauthenticatedView();
  }

  private _displayUnauthenticatedView(): void {
    // Navigate to the 'unauthenticated' view without changing the URL in the browser's address bar.
    // This allows the user to be redirected back to the original URL after re-authentication.
    void this._router.navigate(['/unauthenticated'], { skipLocationChange: true });
  }
}
